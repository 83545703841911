import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      CONTACT_TYPES: 'selection/CONTACT_TYPES'
    })
  },
  methods: {
    getContactType(type) {
      let res
      this.CONTACT_TYPES.forEach(t => {
        if (t.value == type) {
          return (res = t)
        }
      })
      return res
    }
  }
}
